import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { loadFonts } from './plugins/webfontloader'
import './wft.css'
import VueCookies from 'vue-cookies'
import 'ant-design-vue/dist/reset.css';
import Antd from 'ant-design-vue';

loadFonts()

createApp(App)
  .use(router)
  .use(Antd)
  .use(VueCookies)
  .mount('#app')
