<script setup>
    const goHome = () => {
        window.location.href = '/'
    }
</script>

<template>
    <div class="header-container bg-white shadow-lg px-2 py-1">
        <router-link to='/' @click="goHome()" class="w-fit flex">
            <div class="text-md">Youtube Monitor</div>
        </router-link>
    </div>
</template>