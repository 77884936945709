export default {
    api: {
        // base: "http://localhost:4007",
        base: "https://ytmon.srv-ziqlabs-1.my.id",
    },
    order: {
        availableMonths: [
            {
                month: 2,
                price: 12000    
            },
            {
                month: 4,
                price: 28000
            },
            {
                month: 7,
                price: 53000
            },
            {
                month: 10,
                price: 64000
            }
        ]
    }
}