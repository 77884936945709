<script setup>
    import {watch,ref, onMounted} from 'vue'
    import { useRouter } from 'vue-router'
    import { mdiViewDashboard, mdiAccountGroup, mdiCartVariant, mdiBrightnessPercent,mdiYoutubeSubscription } from '@mdi/js'
    import Icon from '@jamescoyle/vue-icon'
    
    const openedKeys = ref([  ])
    const router = useRouter()
    const isAdmin = ref(false)

    const handleClick = (e) => {
      openedKeys.value = e
    }

    const handleSelect = e => {
      router.push(e.key)
    }
    
    const refreshIsAdmin = () => {
      req('/api/user-isadmin', 'GET')
        .then(data => {
          isAdmin.value = data.state
        })
        .catch(err => console.error(err.message || err))
    }

    onMounted(refreshIsAdmin)
</script>

<template>
    <a-menu
      id="sidebar"
      style="width: 230px; height: calc(100vh - 27px); max-height: calc(100vh - 27px); overflow-y: auto;"
      :openKeys="openedKeys"
      :selectedKeys="[router.currentRoute.value.fullPath]"
      mode="inline"
      @openChange="handleClick"
      @select="handleSelect"
    >
      <a-menu-item key="/app">
        <template #icon>
          <Icon :path="mdiViewDashboard" type="mdi" />
        </template>
        <span>Dashboard</span>
      </a-menu-item>
      <a-sub-menu key="order-sub">
        <template #icon>
          <Icon :path="mdiCartVariant" type="mdi" />
        </template>
        <template #title>Pembelian</template>
        <a-menu-item-group key="order">
          <a-menu-item key="/app/pembelian/perpanjang-layanan">Perpanjang / Beli</a-menu-item>
          <a-menu-item key="/app/pembelian/order-list">Daftar Pembelian</a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>
      <a-menu-item key="/app/kelola-voucer" v-if="isAdmin">
        <template #icon>
          <Icon :path="mdiBrightnessPercent" type="mdi" />
        </template>
        <span>Kelola Voucer</span>
      </a-menu-item>
      <a-sub-menu key="sub2" @titleClick="titleClick" v-if="isAdmin">
        <template #icon>
          <Icon :path="mdiAccountGroup" type="mdi" />
        </template>
        <template #title>Kelola Pengguna</template>
        <a-menu-item key="/app/user-list">Daftar Pengguna</a-menu-item>
      </a-sub-menu>
      <a-menu-item key="/app/channels">
        <template #icon>
          <Icon :path="mdiYoutubeSubscription" type="mdi" />
        </template>
        <span>Channels</span>
      </a-menu-item>
    </a-menu>
  </template>
  <script>
  import { defineComponent, ref, watch } from 'vue';
  import { MailOutlined, QqOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';
import req from '@/utils/req';
  export default defineComponent({
    setup() {
      const selectedKeys = ref(['1']);
      const openKeys = ref(['sub1']);
      const handleClick = e => {
        console.log('click', e);
      };
      const titleClick = e => {
        console.log('titleClick', e);
      };
      watch(
        () => openKeys,
        val => {
          console.log('openKeys', val);
        },
      );
      return {
        selectedKeys,
        openKeys,
        handleClick,
        titleClick,
      };
    },
    components: {
      MailOutlined,
      QqOutlined,
      AppstoreOutlined,
      SettingOutlined,
    },
  });
  </script>