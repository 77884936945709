import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: "register",
    component: () => import('@/views/Register.vue')
  },
  {
    path: "/app",
    name: "Dashboard home",
    component: () => import('@/views/app/Dashboard/index.vue')
  },
  {
    path: "/app/user-list",
    name: "User List",
    component: () => import('@/views/app/UserList/index.vue')
  },
  {
    path: "/app/pembelian/perpanjang-layanan",
    name: "Pembelian Baru",
    component: () => import('@/views/app/Pembelian/New.vue')
  },
  {
    path: "/app/kelola-voucer",
    name: "Kelola Voucer",
    component: () => import("@/views/app/KelolaVoucer/index.vue")
  },
  {
    path: "/app/pembelian/order-list",
    name: "Daftar Pembelian",
    component:() => import('@/views/app/Pembelian/OrderList.vue')
  },
  {
    path: "/app/channels",
    name: "Channels",
    component: () => import('@/views/app/Channels/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
