<script setup>
import Header from '../Header.vue'
import Sidebar from './Sidebar'
import req from '@/utils/req'
import { onMounted } from 'vue'

// Already authenticated redirector
onMounted(() => {
    req('/session/isValid')
        .then(({valid}) => {
            if(!valid) {
                window.location.href = '/'
            }
        })
        .catch(err => window.location.href = '/')
})
// end of already authenticated redirector
</script>

<template>
    <div>
        <Header />
        <div class="flex items-start">
            <Sidebar />
            <slot></slot>
        </div>
    </div>
</template>