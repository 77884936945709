import axios from 'axios'
import config from '@/config'

export default function req(path, method='GET', data={}) {
    return new Promise((resolve, reject) => {
        const url = `${config.api.base}${path}`
        axios({
            method,
            data,
            headers: {
                session: $cookies.get('session')
            },
            url
        })
        .then(({data}) => {
            if(data.error) {
                reject(data.msg)
            } else {
                resolve(data.data)
            }
        })
        .catch(err => reject(err.message || err))
    })
}